import Banner from './../assets/ECC-banner.png';
import Logo from './../assets/EastCoastCoders.png';
import Shield from './../assets/1.png';
import Browser from './../assets/2.png';
import Cloud from './../assets/3.png';
import './../App.css';

function HomePage(){
    return(
        <div>
            <div className='home-banner-container' style={{display:'flex',justifyContent:'center',backgroundImage: `url(${Banner})`,flexDirection:'column' }}>
                
                    <div className='home-banner-box'>
                        <p id='home-banner-heading'>Full stack SAAS Enterprise - Mobile Development</p>
                        <p id='home-banner-subheading'>We provide full stack Software as a Service solutions, mobile development, and cloud solutions for enterprise. Our solutions are highly scalable and secure to meet all of your company's needs.</p>
                        
                    </div>
            </div>
            
        </div>
    )
}

export default HomePage;