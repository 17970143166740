import './../App.css';
import logo from './../assets/EastCoastCodersWide.png'

function Header() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="Header-logo">
          <img src={logo} alt="logo" width={400}/>
        </div>
        <div className="Header-menu">
          <div className='Menu-container'>
            
          </div>
          
        </div>
      </header>
      
    </div>
  );
}

export default Header;